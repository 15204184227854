import * as React from 'react';
import { PageProps } from 'gatsby';
import 'semantic-ui-css/semantic.min.css';
import './index.scss';
import '../sass/main.scss';
// @ts-ignore

import favicon from './../images/favicon.png';
import mestekLogo from './../images/mestek-logo.svg';

import Layout from '../layouts/layout/Layout';
import { createMedia } from '@artsy/fresnel';

// Sections
import { Helmet } from 'react-helmet';
import JobAlerts from '../components/JobAlerts/JobAlerts';

const NotFoundPage: React.FC<PageProps> = () => {
	const menuItems = [
		{ name: 'Home', href: '/' },
		{ name: 'Job Opportunities', href: '/search' },
		{ name: 'Benefits', href: '/benefits' }
	];

	const { MediaContextProvider, Media } = createMedia({
		breakpoints: {
			mobile: 0,
			tablet: 900,
			computer: 1240
		}
	});

	return (
		<Layout
			media={Media}
			mediaContextProvider={MediaContextProvider}
			logoSrc={mestekLogo}
			menuItems={menuItems}
		>
			{' '}
			<Helmet>
				<title>{'Mestek Careers- Not Found'}</title>
				<meta name="description" content={'Not found'} />
				<link
					rel="icon"
					type="image/png"
					href={favicon}
					sizes="32x32"
				/>
			</Helmet>
			<div className={'container-xxl py6 py5-lg content'}>
				<div className={'c'}>
					<h1
						className={''}
						style={{
							fontSize: '24px',
							textAlign: 'center'
						}}
					>
						<span className={''} style={{ fontSize: '96px' }}>
							404
						</span>
						<br /> Page Not Found.
					</h1>
					<a href="/" className="go-back">
						Go back
					</a>
				</div>
			</div>
			<JobAlerts />
		</Layout>
	);
};
export default NotFoundPage;
